import { Controller } from "@hotwired/stimulus"
import moment from "moment";
import 'src/core-ui/daterangepicker';

export default class extends Controller {
  connect() {
    $(this.element).daterangepicker({ 
      autoUpdateInput: false,
      maxDate: moment(),
      maxSpan: true,
      "dateLimit": {
          "month": 1
      },
    })
    $(this.element).on('apply.daterangepicker', function(e, picker){
      $(e.target).val(`${picker.startDate.format('L')} - ${picker.endDate.format('L')}`).change();
    });
  }
}

