import { Controller } from '@hotwired/stimulus';
import IMask from "imask";

export default class extends Controller {
  static values = {pattern: String};

  connect() {
    IMask(this.element, { mask: this.patternValue });
  }
}
