import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["templateItem", "list", "item", "remove"];
  static values = { templateId: String };

  connect() {
    this.templateID = new RegExp(this.templateIdValue, 'g')
  }

  add(event) {
    event.preventDefault();
    var item = this.templateItemTarget.innerHTML.replace(this.templateID, new Date().valueOf());
    this.listTarget.insertAdjacentHTML('beforeend', item);
  }

  remove(event) {
    event.preventDefault();
    const index = this.removeTargets.findIndex(t => t === event.target);
    const element = this.itemTargets[index];
    element.querySelector("input[name*='_destroy']").value = 1;
    element.style.display = 'none';
  }
}
