import { Controller } from '@hotwired/stimulus';
import $ from "jquery";

export default class extends Controller {
  static values = { isAuto: { type: Boolean, default: false } }

  connect() {
    if (this.isAutoValue) {
      $(this.element).on('change', e => this.send(e));
    }
  }

  send(event) {
    event.preventDefault();
    const url = window.location.pathname + '?' + $(this.element).serialize();
    Turbo.visit(url);
  }
}

