import { Controller } from '@hotwired/stimulus';
import $ from 'jquery'

export default class extends Controller {
  static targets = ["remove", "input", "button"];

  static values = {
    hasErrors: Boolean
  }

  connect() {
    if (this.hasErrorsValue) {
      this.toggle(new PointerEvent("click"));
    }
  }

  toggle(event) {
    event.preventDefault();

    this.inputTargets.forEach(function (e) {
      e.disabled = false
    })
    this.buttonTargets.forEach(function (e) {
      e.style.visibility = "visible";
    })
    this.removeTargets.forEach(function (e) {
      $(e).hide();
    })
  }

}
