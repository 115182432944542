import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input", "removeInput", "removeButton", "templateItem", "list"];
  static values = { defaultZip: String };

  remove(event) {
    event.preventDefault();

    const index = this.removeButtonTargets.findIndex(t => t === event.target);
    this.removeInputTargets[index].remove();
  }

  add(event) {
    event.preventDefault();
    var item = this.templateItemTarget.innerHTML.replaceAll(this.defaultZipValue, this.inputTarget.value)
    this.listTarget.insertAdjacentHTML('beforeend', item);
    this.inputTarget.value = '';
  }
}
