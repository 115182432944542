import { Controller } from '@hotwired/stimulus';
import $ from 'jquery'

export default class extends Controller {
  static targets = ["reason", "container", "return", "description"]

  rerender() {
    const reason = this.reasonTarget.value

    if (reason === 'wrong size') {
      this.containerTarget.innerHTML = this.descriptionTarget.innerHTML
      $(this.submitTarget).prop('disabled', true)
    } else if (reason === 'product(s) damaged') {
      this.containerTarget.innerHTML = this.returnTarget.innerHTML
      $(this.submitTarget).prop('disabled', true)
    } else {
      this.containerTarget.innerHTML =  ''
      $(this.submitTarget).prop('disabled', false)
    }
  }
}
