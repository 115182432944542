import { Controller } from "@hotwired/stimulus";
import $ from 'jquery'
import 'timepicker';
import 'src/core-ui/daterangepicker';
import coreui from '../src/core-ui/coreui.bundle.min';

const timepickerOpts = {
  step: 30,
  minTime: '7:00 am',
  maxTime: '6:00 pm',
}

export default class extends Controller {
  static targets = ["modal", "startTimeInput", "endTimeInput", "dateInput", "timezoneInput"];

  connect() {
    this.modal = new coreui.Modal(this.modalTarget);
    $(this.timezoneInputTarget).val(Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.initDatePicker()
    this.initTimePickers()
  }

  openModal({ detail: { start, end, guide } }) {
    $(this.startTimeInputTarget).timepicker('setTime', start._date)
    $(this.endTimeInputTarget).timepicker('setTime', end._date);
    $(this.endTimeInputTarget).timepicker('option', 'minTime', end._date);
    $(this.endTimeInputTarget).timepicker('option', 'durationTime', start._date);
    this.datePicker.setStartDate(start._date);
    this.datePicker.setEndDate(start._date);
    this.modal.show();
  }

  disconnect() {
    this.modal.dispose()
    $(this.startTimeInputTarget).timepicker('remove');
    $(this.endTimeInputTarget).timepicker('remove');
  }

  initDatePicker() {
    $(this.dateInputTarget).daterangepicker({ singleDatePicker: true });
    this.datePicker = $(this.dateInputTarget).data('daterangepicker');
  }

  initTimePickers() {
    let controller = this;

    $(this.startTimeInputTarget).timepicker({...timepickerOpts, ...{ maxTime: '5:30 pm' }});
    $(this.endTimeInputTarget).timepicker({ ...timepickerOpts, ...{ showDuration: true }});

    $(this.startTimeInputTarget).on('changeTime', function() {
      const minEndTime = $(this).timepicker('getTime');
      const startTime = new Date(+minEndTime + 30 * 60000);

      $(controller.endTimeInputTarget).timepicker('setTime', startTime);
      $(controller.endTimeInputTarget).timepicker('option', 'minTime', startTime)
      $(controller.endTimeInputTarget).timepicker('option', 'durationTime', minEndTime)
    })
  }
}

